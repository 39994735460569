<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <!-- <vuexy-logo />
         -->
         <b-img   :src="logo" alt="Wahyu Budi Santosa" />
        <h1 class="brand-text text-primary ml-1" style="font-size:35px;">
          Cinta Lapangan  
        </h1>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-1 ">
          <b-img fluid :src="imgUrl" alt="Login V2"  style="float: left;"/>
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
         Cinta Lapangan👋
          </b-card-title>
          <b-card-text class="mb-2">
            Dinas PU Bina Marga dan Cipta Karya Provinsi Jawa Tengah
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <!-- email -->
              <b-form-group label="Username" label-for="login-email">
                <validation-provider #default="{ errors }" name="Email" rules="required">
                  <b-form-input id="login-email" v-model="userEmail" :state="errors.length > 0 ? false:null"
                    name="login-email" placeholder="Username" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label> 
                </div>
                <validation-provider #default="{ errors }" name="Password" rules="required">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null">
                    <b-form-input id="login-password" v-model="password" :state="errors.length > 0 ? false:null"
                      class="form-control-merge" :type="passwordFieldType" name="login-password"
                      placeholder="············" />
                    <b-input-group-append is-text>
                      <feather-icon class="cursor-pointer" :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <div class="d-flex justify-content-between">
                  <label for="login-password"> </label>
                  <router-link to="/lupa_password">
                    <!-- <small>Forgot Password?</small> -->
                  </router-link> 
                </div>
              </b-form-group>

             

              <!-- submit buttons -->
              <b-button type="submit" variant="primary" block 
              @click="validationForm()">
               Sign in <i class="fa fa-sign-in ml-1"></i> 
              </b-button>
              <!-- <router-link to="registrasi">
                <b-button type="submit" variant="warning" block class="mt-1" >
               <i class="fa fa-users"></i> Registrasi Akun
              </b-button>
              </router-link> -->
              
            </b-form>
          </validation-observer>

          <!-- <b-card-text class="text-center mt-2">
            <span>New on our platform? </span>
            <b-link :to="{name:'page-auth-register-v2'}">
              <span>&nbsp;Create an account</span>
            </b-link>
          </b-card-text> -->

          <!-- divider -->
          <!-- <div class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div> -->

          <!-- social buttons -->
          <!-- <div class="auth-footer-btn d-flex justify-content-center">
            <b-button variant="facebook" href="javascript:void(0)">
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button variant="twitter" href="javascript:void(0)">
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button variant="google" href="javascript:void(0)">
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button variant="github" href="javascript:void(0)">
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div> -->
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
  /* eslint-disable global-require */
  import {
    ValidationProvider,
    ValidationObserver
  } from 'vee-validate'
  import VuexyLogo from '@core/layouts/components/Logo.vue'
  import {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
  } from 'bootstrap-vue'
  import {
    required,
    email
  } from '@validations'
  import {
    togglePasswordVisibility
  } from '@core/mixins/ui/forms'
  import store from '@/store/index'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import axios from '../../config/Axios';
  export default {
    components: {
      BRow,
      BCol,
      BLink,
      BFormGroup,
      BFormInput,
      BInputGroupAppend,
      BInputGroup,
      BFormCheckbox,
      BCardText,
      BCardTitle,
      BImg,
      BForm,
      BButton,
      VuexyLogo,
      ValidationProvider,
      ValidationObserver,
    },
    mixins: [togglePasswordVisibility],
    data() {
      return {
        status: '',
        password: '',
        userEmail: '',
        sideImg: require('@/assets/images/logo/cinta_lokasi.png'),
        logo: require('@/assets/images/logo/vuexy-logo.png'),
        // validation rulesimport store from '@/store/index'
        required,
        email,
      }
    },
    computed: {
      passwordToggleIcon() {
        return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
      },
      imgUrl() {
        if (store.state.appConfig.layout.skin === 'dark') {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.sideImg = require('@/assets/images/logo/cinta_lapangan.png')
          return this.sideImg
        }
        return this.sideImg
      },
    },
    methods: {
      async Profil(isAuthenticated) {
        const self = this;
        await axios({
            method: 'PUT',
            url: '/api/aut/profil',
            headers: {
              'Authorization': isAuthenticated
            }
          })
          .then(function (response) {
            self.resultUser = response.data;
            // self.SetUser(response.data.result);
          }).catch(err => {
            console.log(err);
          });

      },
      async get_ip() {
        const self = this;
        await axios_d({
            method: 'GET',
            url: 'https://api.ipify.org?format=json',
            responseType: 'json',
          })
          .then(function (response) {
            self.ip = response.data.ip
          }).catch(err => {
            console.log(err);
          });

      }, 

      validationForm() {
        
        this.$refs.loginValidation.validate().then(success => {
          if (success) { 
            const self = this;
            axios({
                method: 'POST',
                url: '/api/aut/login',
                data: {
                  username: self.userEmail,
                  password: self.password,
                  ip: self.ip
                }
              })
              .then(function (response) { 
                if (response.data.status == '201') {
                   
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'opppssss... terjadi kesalahan di akses kami, silahkan ulangi kembali',
                    icon: 'EditIcon',
                    variant: 'warning',
                  },
                });
                }
                if (response.data.status == '200') { 
                  localStorage.setItem("authLogin", true);
                  localStorage.setItem("token_auth", response.data.result); 
                  self.$router.push({
                    name: '/home'
                  })
                }
              }).catch(err => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Maaf...',
                    text:' username dan password yang anda masukan belum tepat...',
                    icon: 'EditIcon',
                    variant: 'success',
                  },
                });
              });
          }
        })
      },
    },
  }
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/page-auth.scss';
</style>
